import React from "react"
import moment from "moment"
import renderHTML from "react-render-html"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Base } from "../utils/animations"
// import""from "../images/placeholder.jpg"
// import BgSVG from "../images/svgs/schedule-bg.svg"

import "./blog.css"
import Typography from "../components/typography"

const News = ({ data: { meta, posts } }) => {
  return (
    <Layout>
      <SEO
        slug={meta.slug}
        title={meta.yoast_meta.yoast_wpseo_title}
        description={meta.yoast_meta.yoast_wpseo_metadesc}
      />
      <section className="blog__hero ">
        <div className="blog__container page-wrapper">
          <div className="blog__content">
            <Typography variant="h1">
              Articles to help you understand your students loans
            </Typography>
          </div>
        </div>
        <img
          src={require("../images/hero-bg.svg")}
          alt="alt"
          className="blog__hero-bg"
        />
      </section>
      <div className="page-wrapper news__container">
        <div className="news__grid">
          {posts.edges.map(({ node }) => (
            <Base key={node.slug} className="post">
              <Link to={`/blog/${node.slug}`}>
                <div className="post__image-container">
                  {node.acf.cover_image_post && (
                    <Img
                      fluid={
                        node.acf.cover_image_post.localFile.childImageSharp
                          .fluid
                      }
                      alt={renderHTML(node.acf.cover_image_post.alt_text)}
                    />
                  )}
                </div>
                <div className="post__content">
                  <Typography style={{ color: "#000" }} variant="h3">
                    {renderHTML(node.title)}
                  </Typography>
                  <Typography variant="body1" style={{ color: "#000" }}>
                    {moment(node.date).format("MMMM Do, YYYY")}
                  </Typography>
                </div>
              </Link>
            </Base>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default News

export const query = graphql`
  query {
    posts: allWordpressPost {
      edges {
        node {
          slug
          title
          author {
            name
          }
          date
          categories {
            name
          }
          acf {
            cover_image_post {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 720) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    meta: wordpressPage(slug: { eq: "blog" }) {
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
